<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="9">
          <b-card-group>
            <b-card no-body class="p-4 card-login">
              <b-card-body>
                <validation-observer ref="loginForm" #default="{ invalid }">
                  <b-form @submit.prevent="onLogin">
                    <loading
                      :active.sync="isLoading"
                      :can-cancel="false"
                      :is-full-page="fullPage"
                    ></loading>
                    <center>
                      <h3>¡Bienvenido!</h3>
                      <h4>Si tienes cuenta, Inicia sesión</h4>
                    </center>
                    <br />

                    <b-form-group
                      label="E-mail"
                      description="Email Electronico"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email|min:5"
                      >
                        <b-form-input
                          size="sm"
                          type="text"
                          v-model="user.email"
                          :state="errors.length > 0 ? false : null"
                          autocomplete="email"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group label="Contraseña" description="Contraseña">
                      <validation-provider
                        #default="{ errors }"
                        name="Password"
                        rules="required|min:5"
                      >
                        <b-form-input
                          size="sm"
                          type="password"
                          v-model="user.password"
                          :state="errors.length > 0 ? false : null"
                          autocomplete="password"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-row>
                      <b-col cols="12">
                        <br />
                        <center>
                          <b-button
                            type="submit"
                            :disabled="invalid"
                            class="md-primary md-width rounded-pill w-100 py-1"
                            >INICIAR SESIÓN</b-button
                          >
                          <br />
                          <br />
                          <b-button
                            variant="link"
                            class="px-0 text-primary"
                            to="/reset-password"
                            >¿Olvidaste la contraseña?</b-button
                          >
                        </center>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </b-card-body>
            </b-card>
            <b-card
              no-body
              class="text-white py-8 kenburns-left d-md-down-none card-register-login"
            >
              <div class="text-center center-abs">
                <br />
                <div class="mb-5">
                  <h2 class="text-white">
                    <b>Registrate</b>
                  </h2>
                  <h5 class="text-white">Si sos nuevo, registrá tu cuenta</h5>
                </div>
                <div>
                  <b-button
                    @click="go_register"
                    class="md-secondary md-width rounded-pill p-1"
                    >REGISTRARSE</b-button
                  >
                </div>
              </div>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import UserService from "../services/UserService";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCardGroup,
  BCard,
  BCardBody,
} from "bootstrap-vue";
import { required, email } from "@validations";

export default {
  name: "Login",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCardGroup,
    BCard,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      email,
      isLoading: false,
      fullPage: false,
      user: { email: null, password: null },
    };
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    onLogin() {
      this.$refs.loginForm.validate().then((valid) => {
        if (valid) {
          this.isLoading = true;
          UserService.login({
            email: this.user.email,
            password: this.user.password,
          })
            .then((response) => {
              this.isLoading = false;
              if (response.data) {
                UserService.setUser(response.data.data.user);
                UserService.setToken(response.data.data.token);
                UserService.setTime(response.data.data.time_login);
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Bienvenido ${response.data.data.user.name}`,
                    icon: "CoffeeIcon",
                    variant: "primary",
                  },
                });
                this.$router.push("/dashboard/");
              }
            })
            .catch((reason) => {
              this.isLoading = false;
              if (reason.response) {
                switch (reason.response.data.message) {
                  case "Please Verify Email": {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Revisa tu bandeja de emails`,
                        text: `Es necesario verificar el email`,
                        icon: "AlertOctagonIcon",
                        variant: "danger",
                      },
                    });
                    break;
                  }
                  case "Account Disabled": {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Usuario deshabilitado`,
                        text: `El usuario aun esta pendiente por activar`,
                        icon: "AlertOctagonIcon",
                        variant: "danger",
                      },
                    });
                    break;
                  }
                  case "Unauthorized": {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Datos invalidos`,
                        text: `Usuario o contraseña invalidos`,
                        icon: "AlertOctagonIcon",
                        variant: "danger",
                      },
                    });
                    break;
                  }
                }
              }
            });
        }
      });
    },
    go_login() {
      this.$router.push("/login");
    },
    go_register() {
      this.$router.push("/register");
    },
  },
  computed: {},
};
</script>

<style lang="scss">
.md-secondary {
  width: 150px;
  font-size: 14px !important;
  color: #f68d1e !important;
  background: white !important;
  font-weight: bold !important;
}

.btn-secondary:active {
  background-color: #ffffff !important;
  color: #f68d1e !important;
}

.md-primary {
  font-size: 14px !important;
  width: 150px;
  color: white !important;
  background: #f68d1e !important;
  font-weight: bold !important;
}

.card-login {
  border: 0;
  border-radius: 1rem;
  .card-body h3 {
    line-height: 1.5em;
    color: #f6770d;
    font-size: 1.73125rem;
    font-weight: 700;
  }
}

.form-group legend {
  font-weight: 700;
  font-size: 15px;
}

.bg-gray-default {
  background: #f3f3f3;
}
.md-primary {
  background-image: linear-gradient(
    to right,
    #f68d1e 5%,
    #f05822 100%
  ) !important;
}
h6 {
  color: #838890;
}
.btn-link {
  color: #f68d1e !important;
  font-size: 0.7rem;
  text-decoration: underline;
}

.center-abs {
  position: relative !important;
  left: 0 !important;
  top: 160px !important;
}
.py-8 {
  height: auto;
  background-image: url("/img/auth_background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.card-register-login {
  border-radius: 0rem 1rem 1rem 0rem;
}
@media (max-width: 890px){
  .d-md-down-none{
    display: none !important;
  }
}
</style>
